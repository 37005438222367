import React from "react"
import { graphql } from "gatsby"

const NotFoundPage = () => {
    return (
        <div>
            <h1>404</h1>
            <p>Página não encontrada!</p>
        </div>
    )
}

export default NotFoundPage

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
